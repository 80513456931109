import axios from "axios";
import { Logger } from "../providers/logger";
var publicApiInstance = axios.create();
var isLoggerFlagEnabled = process.env.GATSBY_USE_LOGGER;
publicApiInstance.interceptors.response.use(function (response) {
  if (isLoggerFlagEnabled) {
    Logger.loggerRequest(response);
  }

  return response;
}, function (error) {
  if (isLoggerFlagEnabled && error.response) {
    Logger.loggerRequest(error.response);
  }

  return Promise.reject(error);
});
export { publicApiInstance };