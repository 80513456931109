import axios from 'axios';
import { publicApiInstance } from './public-api-instance';

var getRequest = function getRequest(path, config) {
  if (config === void 0) {
    config = {};
  }

  return axios.get("" + process.env.GATSBY_BASE_URL + path, config);
};

var getBRRequest = function getBRRequest(path, config) {
  if (config === void 0) {
    config = {};
  }

  return axios.get("" + process.env.GATSBY_BR_BASE_URL + path, config);
};

var postRequest = function postRequest(path, body, config) {
  if (body === void 0) {
    body = {};
  }

  if (config === void 0) {
    config = {};
  }

  return publicApiInstance.post("" + process.env.GATSBY_BASE_URL + path, body, config);
};

var postRequestAppEnc = function postRequestAppEnc(path, body, config) {
  if (body === void 0) {
    body = {};
  }

  if (config === void 0) {
    config = {};
  }

  return axios.post("" + process.env.GATSBY_WOMPI_WIDGET_PAYMENTS_URL + path, body, config);
};

var postBRRequest = function postBRRequest(path, body, config) {
  if (body === void 0) {
    body = {};
  }

  if (config === void 0) {
    config = {};
  }

  return axios.post("" + process.env.GATSBY_BR_BASE_URL + path, body, config);
};

var putRequest = function putRequest(path, body, config) {
  if (body === void 0) {
    body = {};
  }

  if (config === void 0) {
    config = {};
  }

  return axios.put("" + process.env.GATSBY_BASE_URL + path, body, config);
};

var deleteRequest = function deleteRequest(path, config) {
  if (config === void 0) {
    config = {};
  }

  return axios.delete("" + process.env.GATSBY_BASE_URL + path, config);
};

export { getRequest, postRequest, putRequest, deleteRequest, getBRRequest, postBRRequest, postRequestAppEnc };