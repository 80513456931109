import "core-js/modules/es.array.reduce";
import copy from 'copy-to-clipboard';
export var getQueryParams = function getQueryParams(keys) {
  var params = new URLSearchParams(window.location.search);
  return keys && keys.reduce(function (obj, key) {
    var _Object$assign;

    return Object.assign({}, obj, (_Object$assign = {}, _Object$assign[key] = params.get(key) || '', _Object$assign));
  }, {});
};
export var copyToClipboard = function copyToClipboard(str) {
  try {
    copy(str);
    return true;
  } catch (e) {
    return false;
  }
};