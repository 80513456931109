export var URLParamsKey;

(function (URLParamsKey) {
  URLParamsKey["CLIENT_ID"] = "clientId";
  URLParamsKey["PIX_NUMBER"] = "pixNumber";
  URLParamsKey["MISSING_PARAMS_ERROR"] = "As informa\xE7\xF5es fornecidas s\xE3o inv\xE1lidas!";
})(URLParamsKey || (URLParamsKey = {}));

export var COPY_MESSAGE;

(function (COPY_MESSAGE) {
  COPY_MESSAGE["COPIED"] = "Chave copiada com sucesso";
  COPY_MESSAGE["FAILED"] = "Falha ao copiar chave";
})(COPY_MESSAGE || (COPY_MESSAGE = {}));

export var TOASTER_TIMEOUT = 3000;