import styled, { css } from 'styled-components';
import { LoanInfoCard, SCREEN_SIZE, Paragraph } from '@addi/ui';
var horizontalCentered = css(["display:flex;align-items:center;flex-direction:column;"]);
export var ErrorContainer = styled(LoanInfoCard).withConfig({
  displayName: "ErrorScreenstyled__ErrorContainer",
  componentId: "lfqwtl-0"
})([""]);
export var ErrorCard = styled.div.withConfig({
  displayName: "ErrorScreenstyled__ErrorCard",
  componentId: "lfqwtl-1"
})(["background-color:#FFFFFF;border-radius:8px;display:flex;align-items:center;justify-content:center;flex-direction:column;"]);
export var TitleImage = styled.img.withConfig({
  displayName: "ErrorScreenstyled__TitleImage",
  componentId: "lfqwtl-2"
})(["width:50px;margin:10px;"]);
export var Title = styled(Paragraph).withConfig({
  displayName: "ErrorScreenstyled__Title",
  componentId: "lfqwtl-3"
})(["line-height:1.2rem;font-weight:bold;margin:5px 0;"]);
export var Subtitle = styled(Paragraph).withConfig({
  displayName: "ErrorScreenstyled__Subtitle",
  componentId: "lfqwtl-4"
})(["text-align:center;margin:0;"]);
export var ActionsContainer = styled.div.withConfig({
  displayName: "ErrorScreenstyled__ActionsContainer",
  componentId: "lfqwtl-5"
})(["width:96%;margin-top:20px;", " button{width:98%;margin:10px 0;max-width:320px;}@media (min-width:", "){button{max-width:355px;}}"], horizontalCentered, SCREEN_SIZE.DESKTOP.min);