import styled, { keyframes } from 'styled-components';
import { Header3, Paragraph as AddiParagraph } from '@addi/ui';
var fadeIn = keyframes(["0%{opacity:0;}100%{opacity:1;}"]);
export var MainContainer = styled.div.withConfig({
  displayName: "PixInfoComponentstyled__MainContainer",
  componentId: "rsgdf-0"
})(["display:flex;align-items:center;flex-direction:column;width:100%;"]);
export var QRCodeImage = styled.img.withConfig({
  displayName: "PixInfoComponentstyled__QRCodeImage",
  componentId: "rsgdf-1"
})(["display:block;width:100%;height:100%;max-width:10rem;max-height:10rem;margin:1rem auto;"]);
export var CopyButton = styled.button.withConfig({
  displayName: "PixInfoComponentstyled__CopyButton",
  componentId: "rsgdf-2"
})(["display:flex;align-items:center;justify-content:space-between;cursor:pointer;position:relative;width:100%;margin-top:0.875rem;padding:1rem;border:none;border-radius:2rem;box-shadow:0px 4px 4px rgba(0,0,0,0.25);background:#FFFFFF;max-width:523px;& > span{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}& > img{display:block;margin-left:0.25rem;}"]);
export var WelcomeName = styled(Header3).withConfig({
  displayName: "PixInfoComponentstyled__WelcomeName",
  componentId: "rsgdf-3"
})([""]);
export var Paragraph = styled(AddiParagraph).withConfig({
  displayName: "PixInfoComponentstyled__Paragraph",
  componentId: "rsgdf-4"
})(["text-align:center;max-width:523px;"]);
export var Toaster = styled.div.withConfig({
  displayName: "PixInfoComponentstyled__Toaster",
  componentId: "rsgdf-5"
})(["position:absolute;left:0;right:0;top:-3.75rem;padding:1rem;text-align:center;color:white;background:green;box-shadow:0px 4px 10px rgba(0,0,0,0.25);@media (prefers-reduced-motion:no-preference){animation-name:", ";animation-duration:250ms;animation-fill-mode:backwards;}"], fadeIn);